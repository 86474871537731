import React from 'react'
import Layout from '@compositions/Layout'

const breadcrumbs = [{ key: 'admin', text: 'Admin', url: '/admin' }]

const AdminPage = () => (
  <Layout breadcrumbs={breadcrumbs}>
    <h1>Admin Page</h1>
    <p>Lorem ipsum dolor sit amet consectetum...</p>
  </Layout>
)

export default AdminPage
